import React from 'react';
import { theme } from '../theme';
import { Footer } from '../components/Footer';
import { Heading } from '../components/Text/Heading';
import { graphql } from 'gatsby';
import { Text } from '../components/Text/Text';
import Dropzone, { FileWithPreview } from 'react-dropzone';
import { Button } from '../components/Button/Button';
import { canvasToBlob, createObjectURL } from 'blob-util';
import { Layout } from '../layouts';
import { Head } from '../components/Head';

export interface ShadowProps {}

export interface ShadowState {
  file?: FileWithPreview;
}

export default class Shadow extends React.Component<ShadowProps> {
  state: ShadowState = {};
  handleDrop = (accepted: FileWithPreview[]) => {
    const [file] = accepted;
    this.setState({ file });
  };

  shadowify = () => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
        context.canvas.height = image.height + 100;
        context.canvas.width = image.width + 100;

        // large: '0 15px 35px rgba(50,50,93,.1)',
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 15;
        context.shadowBlur = 35;
        context.shadowColor = 'rgba(50,50,93,.2)';
        context.drawImage(image, 50, 50);

        // medium: '0 5px 15px rgba(0,0,0,.07)',
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 5;
        context.shadowBlur = 15;
        context.shadowColor = 'rgba(0,0,0,.07)';
        context.drawImage(image, 50, 50);

        canvasToBlob(canvas, 'image/png').then(blob => {
          window.location.href = createObjectURL(blob);
        });
      };

      image.src = dataUrl as string;
    };
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.readAsDataURL(this.state.file);
  };

  render() {
    return (
      <Layout>
        <Head title="Shadowizer">
          <meta name="robots" content="noindex" />
        </Head>
        <div
          css={{
            padding: '2rem',
            [theme.media.medium]: {
              margin: '6rem auto',
              padding: 0,
              width: '75%',
            },
          }}
        >
          <div>
            <Heading
              component="h1"
              css={{
                whiteSpace: 'nowrap',
              }}
            >
              Shadowizer
            </Heading>
            <Text size={0}>
              Add beautiful shadows to your images. Helpful when uploading to
              Twitter or Github.
            </Text>
          </div>
          <div css={{ marginTop: '2rem' }}>
            <div
              css={{
                display: 'flex',
                height: 500,
                width: '75%',
                alignItems: 'center',
                justifyContent: 'center',
                background: this.state.file
                  ? theme.color.offWhiteLighter
                  : 'none',
              }}
            >
              {this.state.file ? (
                <div
                  css={{
                    textAlign: 'center',

                    width: '100%',
                  }}
                >
                  <Heading size={2}>Delicious!</Heading>
                  <Text size={0} css={{ maxWidth: '40ch', margin: '0 auto' }}>
                    Click the button below and enjoy your new beautiful image!
                    Copy it into Twitter or Github and spread the love.
                  </Text>
                  <Button css={{ marginTop: '2rem' }} onClick={this.shadowify}>
                    Shadowize It!
                  </Button>
                </div>
              ) : (
                <Dropzone
                  accept="image/jpeg, image/png"
                  onDrop={this.handleDrop}
                  css={{ width: '100%', height: '100%' }}
                >
                  <div
                    css={{
                      borderRadius: '1rem',
                      border: '4px dashed',
                      borderColor: theme.color.grayLightest,
                      padding: '.5rem',
                      height: '100%',
                    }}
                  >
                    <div
                      css={{
                        height: '100%',
                        display: 'flex',
                        borderRadius: 9,
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: theme.color.offWhite,
                      }}
                    >
                      <div css={{ textAlign: 'center' }}>
                        <img
                          css={{
                            width: 300,
                            mixBlendMode: 'darken',
                            marginBottom: '1rem',
                          }}
                          src="https://cdn.dribbble.com/users/26059/screenshots/4053924/schoolism_3.jpg"
                        />
                        <Heading size={2}>Feed Me</Heading>
                        <Text size={0}>Start by adding an image</Text>
                      </div>
                    </div>
                  </div>
                </Dropzone>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    file(relativePath: { eq: "space.jpg" }) {
      childImageSharp {
        sizes {
          ...GatsbyImageSharpSizes
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/blog/**" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            date
          }
        }
      }
    }
  }
`;
